@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
:root {
  /*===== Palet color =====*/
  --main-color: #00943d;
  --secun-color: #ffe572;
  --tercer-color: #58b858;

  --hover-color-3: rgba(255, 255, 255, 0.2);
  --font-color-1: #7152d0;
  --font-color-2: #cedbee;
  --grey: #b5b5b5;
  --grey-font: #aaaaaa;
  --boton-grey: #d5d7db;
  --white: #ffffff;

  /*===== Font and typography =====*/
  --body-font: "Poppins", sans-serif;
  --biggest-font-size: 3rem;
  --h1-font-size: 1.8rem;
  --h2-font-size: 1.5rem;
  --h3-font-size: 1.125rem;
  --normal-font-size: 0.938rem;
  --small-font-size: 0.813rem;
  /*===== Font W =====*/
  --light-font: 400;
  --normal-font: 500;
  --medium-font: 600;
  --Bold-font: 700;

  /*===== z index =====*/
  --z-normal: 1;
  --z-tooltip: 10;
  --z-fixed: 100;
}

html {
  scroll-behavior: smooth;
}

body {
  overflow-x: hidden;
  font-family: var(--body-font);
  color: var(--white);
}
ul,
li {
  list-style: none;
}
a {
  text-decoration: none;
}
/* img {
  width: 100%;
  height: 100%;
  object-fit: cover;
} */
button {
  outline: none;
}
.iconSize {
  font-size: 1.8rem;
}
.bd-container {
  max-width: 1300px;

  margin: 0 auto;
  /* overflow: hidden; */
  padding: 0 1rem;
}
